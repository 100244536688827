const ENV = process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'dev';
const BASE_URL = `https://us-central1-gitl-firebase-${ENV}.cloudfunctions.net`;

const firebaseRequest = async <T>(path: string, payload?: any, method: string = 'GET'): Promise<T> => {
  const fetchConfig = {
    method,
    headers: payload ? { 'Content-Type': 'application/json' } : undefined,
    body: payload ? JSON.stringify(payload) : undefined,
  };

  const res = await fetch(`${BASE_URL}/${path}`, fetchConfig);

  let json;
  try {
    json = await res.json();
  } catch (err) {
    json = null;
  }

  if (res.ok) {
    return json;
  }

  const err = new Error((json && json.error) || 'Unexpected Error. Contact Support.');
  err.code = json.code;
  err.cause = res;

  throw err;
};

export default firebaseRequest;
