import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Message from 'web-react-ui/src/components/collections/Message';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import auth from 'shared/src/modules/auth';
import SuggestModal from 'shared/src/components/promotions-ai/SuggestModal';

import { strings } from '../../i18n';
import businessesModule from '../../modules/businesses';
import EditLocationView from '../locations/EditLocationView';
import LocationDetails from '../locations/LocationDetails';
import Locations from '../locations/Locations';
import DuplicateOfferContainer from '../offers/DuplicateOfferContainer';
import CreateOfferContainer from '../offers/CreateOfferContainer';
import LoyaltyOffers from '../offers/LoyaltyOffers';
import NewEditOfferContainer from '../offers/NewEditOfferContainer';
import OfferDetails from '../offers/OfferDetails';
import PromotionOffers from '../offers/PromotionOffers';
import useProperty from '../useProperty';
import PrivateRoute from '../PrivateRoute';
import BusinessSettings from './BusinessSettings';
import SocialView from './social/SocialView';
import SocialViewPreview from './social/SocialViewPreview';
import EditBusinessView from './EditBusinessView';
import PerformanceOverview from './PerformanceOverview';
import PlansView from './PlansView2/index';
import ContactActions from './ContactActions';

interface Props {
  isPlanViewable: boolean;
  businessId: string;
  isOwner: boolean;
}

const BusinessDetailsView = ({ businessId, isPlanViewable, isOwner }: Props): JSX.Element => {
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  const error = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getError(state));
  const user = useSelector((state: RootStateOrAny) => auth.selectors.getUser(state));
  const property = useProperty();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!business) {
      dispatch(businessesModule.actions.details({ businessId, propertyId: property.id }));
    }

    return () => {
      dispatch(businessesModule.actions.invalidate());
    };
  }, [businessId, property.id]);

  const renderErrAction = (email: string): JSX.Element => {
    const DENIED_MESSAGE = `
      ${strings('dashboard.component.businessDetailsView.fragment.pt1')}\u00A0
      ${email || ''}\u00A0
      ${strings('dashboard.component.businessDetailsView.fragment.pt2')}\u00A0
      ${process.env.REACT_APP_SUPPORT_EMAIL} ${strings('dashboard.component.businessDetailsView.fragment.pt3')}
    `;

    return (
      <View>
        <View.Section narrow>
          <Message error>{error.message === 'Permission denied.' ? DENIED_MESSAGE : error.message}</Message>
        </View.Section>
        {business && <ContactActions businessId={business.id} href="/businesses" />}
      </View>
    );
  };

  const email = user?.email;
  if (error) {
    return renderErrAction(email);
  }

  return (
    <WaitFor waitFor={business}>
      <Switch>
        <Route path="/business/:businessId/social">
          {process.env.REACT_APP_FLAG_SOCIAL_AI === 'true' ? <SocialView isOwner={isOwner} /> : <SocialViewPreview />}
        </Route>
        <Route path={`/business/:businessId/settings`}>
          <BusinessSettings
            baseUrl={`/business/${businessId}/settings`}
            business={business}
            propertyId={property.id}
            isPlanViewable={isPlanViewable}
          />
        </Route>
        <PrivateRoute
          // @ts-ignore
          exact
          // @ts-ignore
          path={`/business/:businessId/plans`}
          // @ts-ignore
          render={(props: any) => <PlansView email={email} business={business} {...props} />}
        />
        <Route exact path="/business/:businessSlug/edit">
          {/* @ts-ignore */}
          <EditBusinessView businessId={businessId} />
        </Route>
        <Route exact path={`/business/:businessId/loyalty`}>
          <LoyaltyOffers />
        </Route>
        <Route exact path={`/business/:businessId/loyalty/performance`}>
          <PerformanceOverview category="loyalty" businessId={businessId} />
        </Route>
        <Route exact path={`/business/:businessId/promotions`}>
          <PromotionOffers />
        </Route>
        <Route exact path={`/business/:businessId/promotions/performance`}>
          <PerformanceOverview category="promotions" businessId={businessId} />
        </Route>
        <Route
          path={`/business/:businessId/offers/new/:offerId`}
          exact
          render={props => (
            <DuplicateOfferContainer offerId={props.match.params.offerId} returnTo={`/business/${businessId}/offers`} />
          )}
        />
        <Route path={`/business/:businessId/promotions/create`}>
          <CreateOfferContainer offerType="promotions" />
        </Route>
        <Route path={`/business/:businessId/loyalty/create`}>
          <CreateOfferContainer offerType="loyalty" />
        </Route>
        <Route path={'/business/:businessId/promotions/suggest'}>
          <SuggestModal propertyId={property?.id} businessId={business?.id} />
        </Route>
        <Route
          path={`/business/:businessId/offers/:offerId`}
          exact
          render={({ match }) => <OfferDetails offerId={match.params.offerId} isOwner={isOwner} />}
        />
        <Route
          path={`/business/:businessId/offers/:offerId/edit`}
          render={({ match }) => (
            <NewEditOfferContainer
              offerId={match.params.offerId}
              redirectTo={`/business/${businessId}/offers/${match.params.offerId}`}
            />
          )}
        />
        <Route
          path={`/business/:businessId/locations/map`}
          render={props => (
            <Locations
              // @ts-ignore
              showView={'map'}
              // @ts-ignore
              history={props.history}
            />
          )}
        />
        <Route
          path={`/business/:businessId/locations/list`}
          render={props => (
            <Locations
              // @ts-ignore
              showView={'list'}
              // @ts-ignore
              history={props.history}
            />
          )}
        />
        <Route
          path={`/business/:businessId/locations/:locationId/edit`}
          render={({ match, history }) => (
            <EditLocationView
              businessId={match.params.businessId}
              locationId={match.params.locationId}
              history={history}
            />
          )}
        />
        <Route
          path={`/business/:businessId/locations/:locationId`}
          render={({ match }) => <LocationDetails locationId={match.params.locationId} />}
        />
        <Route path={`/business/:businessId/locations`}>
          <Redirect to={`/business/${businessId}/locations/list`} />
        </Route>
        <Redirect from="*" to={`/business/${businessId}/social`} />
      </Switch>
    </WaitFor>
  );
};

export default BusinessDetailsView;
export { BusinessDetailsView as Presenter };
