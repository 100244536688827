import {
  Box,
  Flex,
} from '@chakra-ui/react';
import React, {
  useMemo,
  useState,
} from 'react';

import Sidebar from './Sidebar';
import NewFileMeta from './types/NewFileMeta.interface';
import MyImages from './views/MyImages';
import PexelsImages from './views/pexels/PexelsImages';
import SharedImages from './views/SharedImages';
import UnsplashImages from './views/unsplash/UnsplashImages';

const ImageLibrary2 = ({ fileType, onChange, aspectRatio, query }: {
  fileType: string,
  onChange: (imageRefId: string) => void,
  aspectRatio?: number,
  query?: string
}) => {
  const [view, setView] = useState(0);
  const [externalImageForUpload, setExternalImageForUpload] = useState<{src: string, meta: NewFileMeta}>();

  const selectExternalImageForUpload = useMemo(
    () => (src: string, meta: NewFileMeta) => {
      setExternalImageForUpload({ src, meta });
      setView(0);
    },
    [],
  );

  let View;
  switch (view) {
    case 1:
      View = SharedImages;
      break;
    case 2:
      View = PexelsImages;
      break;
    case 3:
      View = UnsplashImages;
      break;
    default:
      View = MyImages;
  }

  return (
    <Flex direction="column" gap="1em" h="100%" overflow="hidden">
      <Flex h="100%" p="1em" pt="0" overflow="hidden" gap="1.5em" direction={['column', 'column', 'row']} align="start">
        <Sidebar index={view} onChange={setView} />
        <Box h="100%" w="100%" overflowY="auto" p="1em">
          <View
            query={query}
            fileType={fileType}
            onChange={onChange}
            aspectRatio={aspectRatio}
            externalImageForUpload={externalImageForUpload}
            selectExternalImageForUpload={selectExternalImageForUpload}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ImageLibrary2;
