/* eslint max-len: 0 */
import {
  Button,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import { useQuerystringParam } from '../../hooks/useQuerystringParam';
import useClient from '../../hooks/useClient';
import useRequest from '../../hooks/useRequest';
import GeneratingSuggestions from './GeneratingSuggestions';
import Suggestion from './Suggestion.interface';
import SuggestionCard from './SuggestionCard';

const runSuggestions = async ({ client, businessId }: { client: any, businessId: string }) => {
  return client
    .businesses.for(businessId)
    .runOfferSuggestionTask();
};

const fetchSuggestions = async ({ client, businessId, taskId }: {
  client: any,
  businessId: string,
  taskId: string
}) => {
  if (!taskId) return null;

  return client
    .businesses.for(businessId)
    .awaitOfferSuggestionTask(taskId);
};

const SuggestModal = ({ propertyId, businessId, isAdmin = false }: {
  propertyId: string,
  businessId: string,
  isAdmin?: boolean
}) => {
  const [taskId, setTaskId] = useQuerystringParam('taskId', '', { squash: true });

  const client = useClient();

  const suggestionTaskRequest = useRequest(
    async () => {
      if (taskId) return null;
      const task = await runSuggestions({ client, businessId });
      return setTaskId(task.id);
    },
    { businessId, taskId },
    { discardError: true },
  );

  const suggestionResultsRequest = useRequest<Array<any>>(
    fetchSuggestions,
    { client, businessId, taskId },
    { discardError: true },
  );

  const retry = useMemo(
    () => () => {
      setTaskId(null);
    },
    [businessId],
  );

  const loading = suggestionTaskRequest.loading || suggestionResultsRequest.loading;

  const backUrl = isAdmin
    ? '../overview'
    : '../promotions';

  return (
    <BigModal open>
      <Container className="c-big-modal" maxW="container.xl">
        <BigModal.Header>
          <PageBackButton to={backUrl}>Back</PageBackButton>
          <Flex alignItems="top" gap="1em" p="1em" pb="0">
            <Heading w="100%" pb="2">Suggested Promotions</Heading>
            <Button
              onClick={retry}
              flexShrink={0}
              isLoading={loading}
              variant="outline"
            >
              Regenerate Suggestions
            </Button>
          </Flex>
        </BigModal.Header>
        <BigModal.Body pb="5em">
          <ErrorAlert
            error={suggestionResultsRequest.error || suggestionTaskRequest.error}
            title="Error Generating Suggestions"
            message={<Button onClick={retry} variant="link">Please try again</Button>}
          />
          {loading
            ? (
              <GeneratingSuggestions />
            )
            : (
              <Flex
                direction={['column', 'column', 'row']}
                gap="1em"
                justifyItems="stretch"
                wrap="wrap"
              >
                {suggestionResultsRequest.result?.map(
                  (s: Suggestion, idx: number) => <SuggestionCard suggestion={s}
                                                                  propertyId={propertyId}
                                                                  businessId={businessId}
                                                                  isAdmin={isAdmin}
                                                                  key={idx} />,
                )}
              </Flex>
            )
          }
        </BigModal.Body>
      </Container>
    </BigModal>
  );
};

export default SuggestModal;
