/* eslint-disable */

import React from 'react';
import format from 'date-fns/format';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link as ChakraLink,
  Stack,
  Text,
  SkeletonText
} from '@chakra-ui/react';
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import IfPermissions from 'web-react-ui/src/components/utils/IfPermissions';
import View from 'web-react-ui/src/components/layout/View';
import SubNav from 'web-react-ui/src/navigation/SubNav';
import Business from 'web-react-ui/src/types/Business.interface';
import AppInstalls from '../apps/AppInstalls';
import BusinessTeam from '../users';
import UserProfile from '../users/UserProfile';
import EditUserViewModel from '../users/EditUserViewModel';
import BusinessHeader from '../BusinessHeader';
import { strings } from '../../../i18n';
import SalesLink from '../../support/SalesLink';

interface BillingAddress {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  state_code: string; // typescript doesn't like that this isn't camelCase
  zip: string;
  country: string;
}

interface Billing {
  billingAddress: BillingAddress;
  isLoading: boolean;
  error: Error;
}

interface Subscription {
  billingPeriodUnit: string;
  nextBillingAt: number;
}

interface Props {
  business: Business;
  plan?: { name: string; id: string };
  isPlanViewable: boolean;
  billing: Billing;
  subscription: Subscription;
  onChange: VoidFunction;
  showBilling: boolean;
  showAppInstalls: boolean;
  propertyId: string;
  baseUrl: string;
}

interface PlanViewProps {
  plan?: { name: string, id: string };
  isLoading: boolean;
  error: Error;
  billingAddress: BillingAddress;
  onChange: VoidFunction;
  subscription: Subscription;
  business: Business;
  isPlanViewable: boolean;
  showBilling: boolean;
}

const BusinessSettingsView = ({
  business,
  plan,
  propertyId,
  isPlanViewable,
  billing,
  baseUrl,
  subscription,
  onChange,
  showBilling,
  showAppInstalls
}: Props): JSX.Element => {
  const { billingAddress, isLoading, error } = billing || {};
  /**
   * Hack time!
   * We limit the ability of Enterprise customers to see and change their Billing Address
   * because many of them are billed manually and some require specific Billing Address setups
   * to invoice properly.
   * See: https://getintheloop.atlassian.net/browse/GITL-5167
   */
  const businessId = business.id;
  const NavMenuItem = (props: { to: string; name: string }) => {
    return <SubNav.Item as={NavLink} activeClassName="active" {...props} />;
  };
  return (
    <>
      <ViewHeader title={strings('dashboard.screen.settings.settings')} />
      <Stack spacing="40px">
        <BusinessHeader business={business} bg="white" p={0} />
        <View.Section style={{ padding: 0 }}>
          <SubNav>
            <NavMenuItem to={`${baseUrl}/team`} name="Team" />
            <NavMenuItem to={`${baseUrl}/plan`} name="Plan" />
            <NavMenuItem to={`${baseUrl}/apps`} name="Apps" />
          </SubNav>
        </View.Section>
        <Switch>
          <Route path={`${baseUrl}/team/:userId/edit`}>
            <EditUserViewModel
              propertyId={propertyId}
              businessId={businessId}
              baseUrl={`/business/${businessId}/settings/team`}
            />
          </Route>
          <Route path={`${baseUrl}/team/:userId`}>
            <UserProfile baseUrl={`/business/${businessId}/settings/team`} />
          </Route>
          <Route exact path={`${baseUrl}/team`}>
            <BusinessTeam />
          </Route>
          <Route path="/business/:businessId/settings/plan">
            <PlanView
              plan={plan}
              onChange={onChange}
              billingAddress={billingAddress}
              business={business}
              isLoading={isLoading}
              error={error}
              isPlanViewable={isPlanViewable}
              subscription={subscription}
              showBilling={showBilling}
            />
          </Route>
          <Route path="/business/:businessId/settings/apps">
            <AppInstalls />
          </Route>
          <Redirect to={`${baseUrl}/team`} />
        </Switch>
      </Stack>
    </>
  );
};

const PlanView = (props: PlanViewProps) => {
  const {
    plan,
    isLoading,
    error,
    billingAddress,
    onChange,
    subscription,
    business,
    isPlanViewable,
    showBilling,
  } = props;
  const isEnterprise = plan?.id === process.env.REACT_APP_PLATFORM_PLAN_ID_ENTERPRISE;
  return (
    <Flex
      direction="row"
      className="plan-container"
      mt="0 !important"
      pt="40px"
      borderTop="1px"
      borderColor="gray.200"
      width="100%"
      wrap="wrap"
      gap="4"
    >
      {plan?.id && (
        <Box flex="1">
          <Flex flexDirection="column" grow={1} pr="1em" borderRight="1px" borderColor="gray.200" data-testid="plan">
            <Heading as="h6" size="md" fontWeight={900} mb={3}>
              {strings('dashboard.screen.settings.currentPlan')}
              <IfPermissions
                context={{ businessId: business.id }}
                permissions={['property.business.chargebee.checkout.create']}
              >
                <Button variant="link" colorScheme="blue" as={Link} to={`/business/${business.id}/plans`} ml="2em">
                  {strings('ui.component.businessSettings.change')}
                </Button>
              </IfPermissions>
            </Heading>
            <CurrentPlan plan={plan} isPlanViewable={isPlanViewable} subscription={subscription} />
          </Flex>
        </Box>
      )}
      {showBilling && !isEnterprise && (
        <Flex flex="1" flexDirection="column" grow={1} pr="1em" data-testid="address">
          <Heading as="h6" size="md" fontWeight={900} mb={3}>
            Billing Address
            <Button variant="link" colorScheme="blue" onClick={onChange} ml="2em">
              {strings('ui.component.businessSettings.change')}
            </Button>
          </Heading>
          <BillingAddress billingAddress={billingAddress} isLoading={isLoading} error={error} />
        </Flex>
      )}
    </Flex>
  );
};

const CurrentPlan = ({
  plan,
  isPlanViewable,
  subscription,
}: {
  plan: { name: string };
  isPlanViewable: boolean;
  subscription: Subscription;
}): JSX.Element => {
  if (!isPlanViewable) {
    return (
      <>
        <Flex align="center">
          <Text>{strings('dashboard.screen.settings.contactUsAt')}&nbsp;</Text>
          <SalesLink label="sales@getintheloop.ca" />
        </Flex>
        <SalesLink label={strings('dashboard.screen.settings.contactUsNow')} classes="ui icon primary button" />
      </>
    );
  }

  return (
    <Stack>
      <Text fontSize="16px">{plan.name}</Text>
      <Subscription subscription={subscription} />
    </Stack>
  );
};

const Subscription = ({ subscription }: { subscription: Subscription }): JSX.Element | null => {
  if (!subscription) return null;

  const billing =
    subscription.billingPeriodUnit === 'year'
      ? strings('shared.billing.frequency.annually')
      : strings('shared.billing.frequency.monthly');
  const nextInvoice = format(subscription.nextBillingAt * 1000, 'MMM d, yyyy');

  return (
    <>
      <Text>
        <b>{strings('dashboard.screen.settings.billed')}</b> {billing}
      </Text>
      <Text>
        <b>{strings('dashboard.screen.settings.nextInvoice')}</b> {nextInvoice}
      </Text>
    </>
  );
};

const BillingAddress = ({
  billingAddress,
  isLoading,
  error,
}: {
  billingAddress: BillingAddress;
  isLoading: boolean;
  error: Error;
}): JSX.Element | null => {
  const countryString = billingAddress?.country === 'US' ? 'United States' : 'Canada';

  if (isLoading) return <SkeletonText maxWidth="30em" />;

  if (error) {
    return (
      <Alert status="error">
        <AlertTitle>{error.message}</AlertTitle>
        <AlertDescription>
          {strings(`dashboard.screen.plansView.error.contactSupport`)}{' '}
          <ChakraLink href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </ChakraLink>
        </AlertDescription>
      </Alert>
    );
  }

  if (!billingAddress) return null;

  return (
    <Box>
      <Text>{billingAddress.line1}</Text>
      {billingAddress.line2 ? <Text>{billingAddress.line2}</Text> : ''}
      {billingAddress.line3 ? <Text>{billingAddress.line3}</Text> : ''}
      <Text>
        {billingAddress.city}, {billingAddress.state_code}, {countryString}
      </Text>
      <Text>{billingAddress.zip}</Text>
    </Box>
  );
};

export default BusinessSettingsView;
