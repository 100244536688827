/* eslint max-len: 0 */
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Button,
  Select,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import useClient from 'shared/src/hooks/useClient';
import useRequest from 'shared/src/hooks/useRequest';
import LocalizationWrapper from '../../reactFinalForm/fields/localizableField/LocalizationWrapper';

const fetchRewrite = async ({ client, form }) => {
  const formValues = form.getState().values;

  const task = await client
    .businesses.for(formValues.businessId)
    .runOfferRewriteTask({
      headline: formValues.headline,
      subhead: formValues.subHeadline,
      details: formValues.details
    });

  const result = await client
    .businesses.for(formValues.businessId)
    .awaitOfferRewriteTask(task.id);

  return result;
};

const RewriteControl = () => {
  const client = useClient();
  const form = useForm();
  const toast = useToast();

  const rewriteRequest = useRequest(fetchRewrite, undefined, { discardError: true });

  const updateOfferFields = useMemo(
    () => ({ headline, subhead, details }) => {
      return form.batch(() => {
        form.change('headlineLocalized.en', headline);
        form.change('subHeadlineLocalized.en', subhead);
        form.change('detailsLocalized.en', details);
      });
    },
    []
  );

  const resetOfferFields = useMemo(
    () => () => {
      const intialValues = form.getState().initialValues;
      updateOfferFields({
        headline: intialValues.headline,
        subhead: intialValues.subHeadline,
        details: intialValues.details
      });
    },
    []
  );

  const handleSelectChange = useMemo(
    () => (event) => {
      if (event.target.value === 'original') return resetOfferFields();
      if (event.target.value === 'newSuggestions') return rewriteRequest.run({ client, form });

      updateOfferFields(rewriteRequest.result[event.target.value]);
    }
  );

  useEffect(
    () => {
      // Auto-set fields to `simple` rewrite result
      if (!rewriteRequest.result) return;
      updateOfferFields(rewriteRequest.result.simple);
    },
    [rewriteRequest.result]
  );

  useEffect(
    () => {
      if (!rewriteRequest.error) return;
      toast({
        title: 'Error Rewriting',
        description: 'Something went wrong while making rewrites. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    [rewriteRequest.error]
  );

  if (rewriteRequest.loading) {
    return (
      <Button
        size="lg"
        variant="ghost"
        colorScheme="blue"
        disabled
        rightIcon={<Spinner />}
        onClick={() => rewriteRequest.run({ client, form })}
      >
        Rewriting...
      </Button>
    );
  }

  if (rewriteRequest.result) {
    return (
      <Select width="auto" onChange={handleSelectChange}>
        <option value="simple">Standard Refresh</option>
        <option value="value">Value+</option>
        <option value="exciting">Excitement+</option>
        <option value="seasonal">Seasonal</option>
        <option value="original">Original Text</option>
        <hr />
        <option value="newSuggestions">Get New Suggestions...</option>
      </Select>
    );
  }

  return (
    <Button
      size="lg"
      variant="ghost"
      colorScheme="blue"
      rightIcon={<FaWandMagicSparkles />}
      onClick={() => rewriteRequest.run({ client, form })}
    >
      Rewrite
    </Button>
  );
};

const LocaleAwareRewriteControl = () => {
  const { locale } = useContext(LocalizationWrapper.context);
  const form = useForm();

  if (!form.getState().values.id) return null;

  // We just can't rewrite stuff for non-english values
  if (locale !== 'en') return null;

  return RewriteControl();
};

export default LocaleAwareRewriteControl;
