import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, AlertIcon, Avatar, Flex, Stack, Text } from '@chakra-ui/react';

import useRequest from 'shared/src/hooks/useRequest';
import usePermissions from 'shared/src/hooks/usePermissions';

import Location from 'web-react-ui/src/types/Location.interface';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import { appTypes } from './appTypes';
import useProperty from '../../useProperty';

const AppInstalls = () => {
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  const apps = useRequest(() => client.businesses.for(business.id).apps.listInstalledApps(), {
    businessId: business.id
  });

  const locations = useRequest(() => client.properties.for(property.id).businesses.for(business.id).locations.list(), {
    businessId: business.id,
    propertyId: property.id
  });

  const hasPermissions = usePermissions({ businessId: business.id }, ['business.app.install.list']);

  return (
    <>
      {hasPermissions && (
        <WaitFor style={{ display: "contents" }} waitFor={apps.settled}>
          <Stack
          align="center"
          spacing={5}>
            {hasPermissions &&
              apps.result?.items?.map((app: any) => {
                const linkedLocation = locations.result?.items.find(
                  (item: Location) => item.id === app.context.locationId
                );
                return (
                  <Flex
                    borderTop="1px"
                    key={app.id}
                    borderColor="gray.200"
                    p={5}
                    as={Link}
                    to={`apps/${app.id}`}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Avatar
                      bg="transparent"
                      name={appTypes[app.appId].name}
                      src={appTypes[app.appId].logo}
                      mr={5}
                      size="lg"
                      borderRadius={8}
                    />
                    <Flex align="flex-start" flexDir="column" justify="center">
                      <Text fontSize="2xl" fontWeight={500}>
                        {appTypes[app.appId].name}
                      </Text>
                      {linkedLocation && (
                        <Text fontSize="lg">
                          Linked to location: <b>{linkedLocation.name}</b>
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                );
              })}
          </Stack>
        </WaitFor>
      )}
      {hasPermissions && apps.settled && (!apps.result || apps.result?.items.length === 0) && (
      <Flex justify="center" sx={{ marginTop: "0 !important" }} borderTop="1px" borderColor="gray.200" pt="40px">
          <Text fontSize="xl" fontWeight={500}>
            No apps have been installed
          </Text>
      </Flex>
      )}
      {hasPermissions === false && (
        <Alert status="warning">
          <AlertIcon />
          You do not have permission to view or modify installed apps.
        </Alert>
      )}
    </>
  );
};

export default AppInstalls;
