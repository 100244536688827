const attributions = new Map();

const imageAttributionService = {
  setAttribution: (url, attribution) => {
    attributions.set(url, attribution);
  },
  getAttribution: (url) => {
    return attributions.get(url);
  },
};

export default imageAttributionService;
