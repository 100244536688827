import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useField } from 'react-final-form';
import { fetchImages as fetchUnsplashImages } from 'shared/src/services/unsplash';
import { fetchImages as fetchPexelsImages } from 'shared/src/services/pexels';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import useRequest from '../../hooks/useRequest';
import { SuggestionImageCarouselField } from './SuggestionImageCarousel';
import Suggestion from './Suggestion.interface';

const fetchImages = async (keywords: Array<string>) => {
  // pick two keywords for Unsplash
  let index = Math.floor(Math.random() * keywords.length);
  const unsplashQueries = [
    keywords[index],
    keywords[(index + 1) % keywords.length],
  ];

  const unsplashPromises = unsplashQueries.map(
    async (q) => {
      try {
        const result = await fetchUnsplashImages({ query: q, limit: 1 });
        return result.items[0].urls.regular;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  );

  // pick two keywords for Pexels
  index = Math.floor(Math.random() * keywords.length);
  const pexelsQueries = [
    keywords[index],
    keywords[(index + 1) % keywords.length],
  ];

  const pexelsPromises = pexelsQueries.map(
    async (q) => {
      try {
        const result = await fetchPexelsImages({ query: q, limit: 1 });
        return result.items[0].src.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  );

  const images = await Promise.all([...unsplashPromises, ...pexelsPromises]);

  return images.filter(Boolean);
};

const SuggestedImages = ({ name, editable, suggestion }: {
  name: string,
  editable: boolean,
  suggestion: Suggestion
}) => {
  const field = useField(name, {});

  const imagesRequest = useRequest(
    async () => {
      if (!suggestion.keywords) return null;
      if (field.input.value) return null;

      const urls = await fetchImages(suggestion.keywords);

      field.input.onChange(urls);

      return urls;
    },
    [suggestion, field.input.value],
  );

  return (
    <Skeleton isLoaded={!imagesRequest.loading} borderRadius="2em">
      <ErrorAlert error={imagesRequest.error} />
      <SuggestionImageCarouselField name={name} editable={editable} />
    </Skeleton>
  );
};

export default SuggestedImages;
