import {
  Center,
  Link,
} from '@chakra-ui/react';
import React, {
  useMemo,
  useState,
} from 'react';
import usePagedList from 'shared/src/hooks/usePagedList';
import { fetchImages } from 'shared/src/services/unsplash';
import UnsplashSearchItem from 'shared/src/types/UnsplashSearchItem.interface';

import imageParams from '../../../../../utils/imageParams';
import withFileType from '../../../../imageWell/withFileType';
import ImageUploadWrapper from '../../../ImageUploadWrapper/ImageUploadWrapper';
import { FileTypeInfo } from '../../../types';
import ImageGrid from '../../ImageGrid';
import LibraryButton from '../../LibraryButton';
import LibraryImage from '../../LibraryImage';
import NewFileMeta from '../../types/NewFileMeta.interface';
import SetFileFunction from '../../types/SetFileFunction.interface';
import EmptyPagedList, { Empty } from '../shared/EmptyPagedList';
import Error from '../shared/Error';
import extractKeywords from '../shared/extractKeywords';
import LoadMore from '../shared/LoadMore';

const getUnsplashKeywords = (image: UnsplashSearchItem, query?: string) => {
  const altKeywords = extractKeywords(image.alt_description);
  const queryKeywords = extractKeywords(query);
  const keywords = ['unsplash', `unsplash-${image.id}`, ...altKeywords, ...queryKeywords];
  return Array.from(new Set(keywords));
};

const UnsplashImages = ({ query, fileType, fileTypeInfo, aspectRatio, selectExternalImageForUpload }: {
  query?: string,
  fileType: string,
  fileTypeInfo: FileTypeInfo,
  aspectRatio?: number,
  selectExternalImageForUpload: (image: string, meta: NewFileMeta) => void
}) => {
  const [setFile, setSetFile] = useState<{ fn: SetFileFunction }>();

  const imagePagedList = usePagedList(
    () => {
      if (!query) return null;
      return fetchImages({ query });
    },
    [query],
    { accumulate: true },
  );

  const onClick = useMemo(
    () => async (image: UnsplashSearchItem) => {
      // If it's safe to, resize the image to fit within the fileType restrictions
      // so we can avoid forcing the cropper.
      const dimensions = fileTypeInfo.restrictions.dimensions;
      const aspect = image.width / image.height;
      const maxAspect = dimensions.maxWidth / dimensions.minHeight;
      const minAspect = dimensions.minWidth / dimensions.maxHeight;

      let originalUrl = image.urls.raw;

      if (maxAspect >= aspect && aspect >= minAspect) {
        const params = {
          fit: 'max',
          w: fileTypeInfo.restrictions.dimensions.maxWidth,
          h: fileTypeInfo.restrictions.dimensions.maxHeight,
        };
        originalUrl = imageParams(
          // image.src.original,
          image.urls.raw,
          params,
        );
      }

      setFile?.fn?.(
        originalUrl,
        image.urls.regular,
        {
          keywords: getUnsplashKeywords(image, query),
          rawUrl: image.urls.raw,
        },
      );
    },
    [setFile, query],
  );

  return (
    <ImageUploadWrapper
      fileType={fileType}
      aspectRatio={aspectRatio}
      skipUpload={true}
      isEmpty={true}
      stealth={true}
      handleDrag={false}
      registerSetExternalUrl={(fn: SetFileFunction) => setSetFile({ fn })}
      forceCrop={true}
      onChange={selectExternalImageForUpload}
    >
      <ImageGrid loading={imagePagedList.loading}>
        {(!imagePagedList.loading || imagePagedList.paging) && imagePagedList.items.map((image: UnsplashSearchItem) => (
          <LibraryButton w="100px" h="100px" key={image.id} onClick={() => onClick(image)}>
            <LibraryImage
              color={image.color}
              url={image.urls.thumb}
            />
          </LibraryButton>
        ))}
        {!(query || imagePagedList.items.length) && (
          <Empty>Enter a search above to get started</Empty>
        )}
        <EmptyPagedList pagedList={imagePagedList}>No images found for "{query}"</EmptyPagedList>
        <Error pagedList={imagePagedList} />
        <LoadMore pagedList={imagePagedList} />
      </ImageGrid>
      <Center w="100%" p="5em">
        <Link href="https://www.unsplash.com" target="_blank" size="sm" color="gray.400">
          Photos provided by Unsplash
        </Link>
      </Center>
    </ImageUploadWrapper>
  );
};


export default withFileType(UnsplashImages);
