import {
  Box,
  Button,
  Flex,
  Stack,
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  offerListViewParamsFactory,
  VIEW_TYPES,
} from 'shared/src/helpers/offerListViewParams';

import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';

import Location from 'web-react-ui/src/types/Location.interface';
import Business from 'web-react-ui/src/types/Business.interface';

import LocationDetailBlock from 'web-react-ui/src/chakra/LocationDetails';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Message from 'web-react-ui/src/components/collections/Message';
import Input from 'web-react-ui/src/components/elements/Input';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import IfPermissions from 'web-react-ui/src/components/utils/IfPermissions';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Offer from 'web-react-ui/src/types/Offer.interface';

import { strings } from '../../i18n';
import businessesModule from '../../modules/businesses';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import MetricsLoyaltyTotalWrapper from '../stats/MetricsLoyaltyTotalWrapper';


const fetchOffers = ({
  query,
  view,
  business,
  locationId,
}: {
  query?: string,
  view: string,
  business: Business,
  locationId: string,
}) => {
  const property = propertyResolver();

  return client
    .properties.for(property.id)
    .businesses.for(business.id)
    .locations.for(locationId)
    .offers.list({
      query, ...offerListViewParamsFactory(view),
    });
};

const fetchStats = ({
  location,
  business,
  days,
}: {
  location: Location,
  business: Business,
  days: number,
}) => {
  if (!location) return null;
  const property = propertyResolver();
  const statsRepo = client
    .properties.for(property.id)
    .businesses.for(business.id)
    .locations.for(location.id)
    .stats;

  return Promise.all([
    statsRepo.getLastNDays({ locationId: location.id, days }),
    statsRepo.getLoyaltyLastNDays({ locationId: location.id, days }),
  ]);
};

const fetchExternalIds = async (
  { propertyId, businessId, locationId }:
  { propertyId: string, businessId: string, locationId: string },
) => {
  const canGetPlaceIds = await client.access.checkPermissions(
    { propertyId, businessId, locationId },
    ['business.location.get'],
  );

  if (!canGetPlaceIds) return undefined;
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.for(locationId)
    .getExternalPlaceIds();
};

const fetchLocationDetails = async ({
  businessId,
  locationId,
}: {
  businessId: string,
  locationId: string,
}) => {
  const property = propertyResolver();
  const locationRepo = client
    .properties.for(property.id)
    .businesses.for(businessId)
    .locations.for(locationId);

  const [location, externalIds] = await Promise.all([
    locationRepo.details(),
    fetchExternalIds({
      propertyId: property.id,
      businessId,
      locationId,
    }),
  ]);

  return {
    ...location,
    externalIds,
  };
};

const buildHref = (offer: Offer) => `/business/${offer.businessId}/offers/${offer.id}`;

const LocationDetails = ({ locationId }: { locationId: string }): JSX.Element => {
  const [days, setDays] = useState(30);
  const [activeItem, setActiveItem] = useState('Offers');
  const [location, setLocation] = useState<Location>();
  const property = propertyResolver();

  // NOTE no business is returned in LO
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const [view, setView] = useQuerystringParam('view', VIEW_TYPES.live, { squash: true });
  const [query, debouncedQueryHandler] = useQueryDebounce(view, business, locationId);

  useEffect(() => {
    const runEffect = async () => {
      try {
        fetchLocationDetails({ businessId: business.id, locationId }).then((data: Location) => {
          setLocation(data);
        });
      } catch (err: any) {
        throw new Error(err);
      }
    };
    runEffect();
  }, [business, locationId]);

  const handleItemClick = (e: any) => {
    setActiveItem(e.currentTarget.dataset.name);
  };

  const handleViewChange = (e: any) => {
    setView(e);
  };

  const options = [
    { key: 1, text: strings('dashboard.screen.locationDetails.options.new'), value: VIEW_TYPES.new },
    { key: 2, text: strings('dashboard.screen.locationDetails.options.live'), value: VIEW_TYPES.live },
    { key: 3, text: strings('dashboard.screen.locationDetails.options.expiringSoon'), value: VIEW_TYPES.expiring },
    { key: 4, text: strings('dashboard.screen.locationDetails.options.expired'), value: VIEW_TYPES.expired },
    { key: 5, text: strings('dashboard.screen.locationDetails.options.draft'), value: VIEW_TYPES.disabled },
  ];

  const breadcrumbs = [
    {
      to: `/business/${business.id}`, title: business.name,
    },
    {
      to: `/business/${business.id}/locations`, title: strings('dashboard.component.navItems.locations'),
    },
    {
      to: '#', title: strings('dashboard.screen.locationDetails.locationDetails'), isCurrentPage: true,
    },
  ];

  if (!location) return <></>;

  return (
    <Box>
      <WaitFor waitFor={location}>
        <Box my="24px">
          <Breadcrumbs items={breadcrumbs} />
          <ViewHeader title={strings('dashboard.screen.locationDetails.locationDetails')}>
            <IfPermissions
              permissions={['business.location.update']}
              context={{ businessId: business?.id }}
            >
              <Button
                colorScheme="blue"
                as={NavLink}
                to={`/business/${business.id}/locations/${location.id}/edit`}
              >
                {strings('dashboard.screen.locationDetails.editDetails')}
              </Button>
            </IfPermissions>
          </ViewHeader>
          <LocationDetailBlock
            location={location}
            externalIds={location.externalIds}
            externalListingsWhitelist={['google']}
          />
          <Menu pointing secondary>
            <Menu.Item
              data-name="Offers"
              name={strings('dashboard.screen.locationDetails.activeItem.offers')}
              active={activeItem === 'Offers'}
              onClick={handleItemClick}
            />
            <Menu.Item
              data-name="Stats"
              name={strings('dashboard.screen.locationDetails.activeItem.stats')}
              active={activeItem === 'Stats'}
              onClick={handleItemClick}
            />
          </Menu>
        </Box>
        {(activeItem === 'Stats') && (
          <Box>
            <div className="flex fww">
              <div style={{ width: '75%' }} />
              <div className="fg1 mt2 m1" style={{ minWidth: '200px' }}>
                <TimeRangeSelector
                  days={days}
                  handleSetRange={({ days: newDays }: { days: number }) => setDays(newDays)}
                />
              </div>
            </div>
            <Request request={fetchStats} params={{ days, property, business, location }}>
              {({ data, loading, error }: {
                data: any[],
                loading: boolean,
                error: { message: string }
              }) => {
                const [stats, loyaltyStats] = data || [];
                return (
                  <WaitFor waitFor={!loading} wrapContents>
                    <Box>
                      {error && <Message error>{error.message}</Message>}
                      <h2>{strings('dashboard.screen.locationDetails.total')}</h2>
                      <Metrics data={stats} plansHref="../plans" />
                      <h2>{strings('dashboard.screen.locationDetails.loyalty')}</h2>
                      {/* @ts-ignore */}
                      <MetricsLoyaltyTotalWrapper businessId={business.id} locationId={location.id} />
                      <MetricsLoyalty data={loyaltyStats} plansHref="../plans" />
                    </Box>
                  </WaitFor>
                );
              }}
            </Request>
          </Box>
        )}
        {(activeItem === 'Offers') && (
          <Stack spacing="20px">
            <Flex alignItems="center">
              <Input
                placeholder={strings('dashboard.screen.locationDetails.field.placeholder.findOffer')}
                fluid
                icon="search"
                onChange={(e: any) => debouncedQueryHandler(e.target.value)}
                className="fg1 mr1"
              />
              <Dropdown
                onChange={(e: any, { value }: { value: string }) => handleViewChange(value)}
                options={options}
                selection
                value={view}
              />
            </Flex>
            <Box>
              <PagedList fetchList={fetchOffers} params={{ query, view, business, locationId }}>
                {(offersList) => (
                  <OffersListTable
                    offersList={offersList}
                    noOffersString={strings('dashboard.screen.locationDetails.noOffers')}
                    buildHref={buildHref}
                    hideBusiness
                  />
                )}
              </PagedList>
            </Box>
          </Stack>
        )}
      </WaitFor>
    </Box>
  );
};

export default LocationDetails;
