import { nextMonday } from 'date-fns';
import {
  getImageFileUrlDimensions,
} from 'web-react-ui/src/components/imageWell/imageUtils';
import imageParams from 'web-react-ui/src/utils/imageParams';
import FormData from './FormData.interface';

const createDraft = async (client: any, formData: FormData, propertyId: string, businessId: string) => {
  const imageResults = await Promise.all(formData.images.map(
    (url) => {
      const croppedImageUrl = imageParams(url, { w: 2560, h: 2560, fit: 'crop' });
      return uploadImage(client, croppedImageUrl, propertyId, businessId);
    },
  ));

  const newDraftPipeline = await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .offers.initialize();

  // The old offer pipeline is gross and bad but it's what we have right now =\
  const newDraft = newDraftPipeline.deprecatedGetOldOfferModel();

  // Clear locations so the update pipeline diff doesn't skip them
  newDraftPipeline.setInstance('locations', []);

  newDraft.headline = formData.headline;
  newDraft.subHeadline = formData.subhead; // Yeah, the sdk does this 😞
  newDraft.details = formData.details;
  newDraft.keywords = [];
  // 🙃
  // eslint-disable-next-line no-underscore-dangle
  newDraft.sourceId = formData._id;
  newDraft.heroImages = imageResults; // It does this, too

  // "Next Monday"
  newDraft.schedules[0].startDate = nextMonday(Date.now()).toISOString().split('T')[0];

  newDraft.flags = newDraft.flags.filter((f: string) => f !== 'exclusive');

  const result = await newDraftPipeline.deprecatedUpdateWithOldOfferModel(newDraft);

  return result.instances.offer.id;
};

const uploadImage = async (client: any, url: string, propertyId: string, businessId: string): Promise<string> => {
  const result = await fetch(url);
  const file = await result.blob();
  const fileType = 'offer-hero-standard';
  const mimeType = file.type;
  const { width, height } = await getImageFileUrlDimensions(url);
  const size = file.size;

  const imageRef = await client.images.create(
    file,
    fileType,
    mimeType,
    width,
    height,
    size,
  );

  await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .images.link(imageRef.id);

  return imageRef.id;
};

export default createDraft;
