import PexelsSearchItem from '../types/PexelsSearchItem.interface';

type Items = Array<PexelsSearchItem>;
type Page = {
  next: string,
  helpers: {
    next: () => Promise<{ items: Items, page: Page }>
  }
}

const buildUrl = (query?: string, limit?: number, url?: string) => {
  if (url) return url;
  // I wouldn't have thought we'd need to do this, but if we don't, `%` gets passed through without encoding
  const encodedQuery = query && encodeURIComponent(query);
  return `https://api.pexels.com/v1/search?query=${encodedQuery}&per_page=${limit}`;
};

const fetchImages = async (
  { query, limit, url }: { query?: string, limit?: number, url?: string },
): Promise<{ items: Items, page: Page }> => {
  const result = await fetch(
    buildUrl(query, limit || 30, url),
    {
      // @ts-ignore
      headers: {
        Authorization: process.env.REACT_APP_PEXELS_API_KEY,
      },
    },
  );

  const json = await result.json();

  return {
    items: json.photos,
    page: {
      next: json.next_page,
      helpers: {
        next: () => fetchImages({ url: json.next_page }),
      },
    },
  };
};

export { fetchImages };
