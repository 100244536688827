import {
  Center,
  Flex,
  Text,
  keyframes,
} from '@chakra-ui/react';
import React from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';

const pulse = keyframes`
  from {
    opacity: 50%
  }
  to {
    opacity: 100%
  }
`;

const animation = `${pulse} 0.8s linear infinite alternate`;

const GeneratingSuggestions = () => {
  return (
    <Center h="100%" color="gray.400">
      <Flex direction="column" alignItems="center" gap="1em" animation={animation}>
        <FaWandMagicSparkles size={48} />
        <Text fontSize="2xl" maxW="26em" textAlign="center" sx={{ textWrap: 'balance' }}>
          Generating promotions for your business based on location and industry
        </Text>
      </Flex>
    </Center>
  );
};

export default GeneratingSuggestions;
