import imageAttributionService from '../components/promotions-ai/imageAttributionService';
import UnsplashSearchItem from '../types/UnsplashSearchItem.interface';
import firebaseRequest from './firebase';

type Items = Array<UnsplashSearchItem>;
type Page = {
  next: string,
  helpers: {
    next: () => Promise<{ items: Items, page: Page }>
  }
}

const getParams = (url?: string) => {
  if (!url) return {};
  const params = new URL(url).searchParams;
  return {
    query: params.get('query') || undefined,
    limit: parseInt(params.get('per_page') || '', 10) || undefined,
    page: parseInt(params.get('page') || '', 10) || undefined,
  };
};

const fetchImages = async (
  { query, limit = 10, page }: { query?: string, limit?: number, page?: number } = {},
): Promise<{ items: Items, page: Page }> => {
  // I wouldn't have thought we'd need to do this, but if we don't, `%` gets passed through without encoding
  const encodedQuery = query && encodeURIComponent(query);
  const response = await firebaseRequest<{ results: Array<UnsplashSearchItem>, nextPage: string }>(
    `/api/1/images/unsplash/search?query=${encodedQuery}&page=${page}&per_page=${limit}`,
  );

  const results: Array<UnsplashSearchItem> = response.results;
  const nextPage = response.nextPage;
  const nextParams = getParams(nextPage);

  // Store attributions for local lookup...
  results.forEach((image: UnsplashSearchItem) => {
    const url = image.urls.regular; // raw is too big
    const attribution = {
      name: image.user.name,
      url: image.user.links.html,
      download_location: image.links.download_location,
    };
    imageAttributionService.setAttribution(url, attribution);
  });

  return {
    items: results,
    page: {
      next: nextPage,
      helpers: {
        next: () => fetchImages(nextParams),
      },
    },
  };
};

const trackDownload = async (url: string) => {
  const downloadLocation = imageAttributionService.getAttribution(url)?.download_location;

  if (!downloadLocation) return false;

  const response = await firebaseRequest<void>(
    'api/1/images/unsplash/track',
    {
      download_location: downloadLocation,
    },
    'POST',
  );
};

export {
  fetchImages,
  trackDownload,
};
