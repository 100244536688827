import React from 'react';
import { useSelector } from 'react-redux';

import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import Business from 'web-react-ui/src/types/Business.interface';

import BrandSettings from 'shared/src/components/social/BrandSettings';
import ContentSettings from 'shared/src/components/social/ContentSettings';
import GeneralSettings from 'shared/src/components/social/GeneralSettings';
import SectionHeader from 'shared/src/components/SectionHeader';
import SocialAccounts from 'shared/src/components/SocialAccounts';
import Profile from 'shared/src/interfaces/Profile.interface';

import { strings } from '../../../../i18n';
import Campaigns from './Campaigns';

const SocialSettings = () => {
  const profile = useSelector<{ social: { profile: Profile } }>(
    (state) => state.social.profile,
  ) as Profile;
  const business = useSelector<{ business: { data: Business } }>(
    (state) => state.business.data,
  ) as Business;

  return (
    <PageLayout>
      <PageBackButton to="../social">
        {strings('ui.label.cancel')}
      </PageBackButton>
      <ViewHeader title="Social AI Settings" />
      <SocialAccounts canEdit profile={profile} business={business} />
      <Campaigns />
      <SectionHeader>Brand Settings</SectionHeader>
      <BrandSettings
        profile={profile}
        businessId={business.id}
      />
      <SectionHeader>Content Settings</SectionHeader>
      <ContentSettings
        profile={profile}
        businessId={business.id}
        hidePostFrequency={true}
      />
      <SectionHeader>General Settings</SectionHeader>
      <GeneralSettings profile={profile} businessId={business.id} />
    </PageLayout>
  );
};

export default SocialSettings;
