import React, {
  useEffect,
  useState,
} from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  AddIcon,
  SearchIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  InputGroup,
  Input,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';

import authModule from 'shared/src/modules/auth';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import usePermissions from 'shared/src/hooks/usePermissions';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import User, { Role } from 'web-react-ui/src/types/User.interface';
import Location from 'web-react-ui/src/types/Location.interface';
import RoleFilter from 'web-react-ui/src/chakra/UserList/RoleFilter';
import UserListView from 'web-react-ui/src/chakra/users/UserListView';
import AddUserView from 'web-react-ui/src/chakra/users/AddUserView';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import businessModule from '../../../modules/businesses';
import client from '../../../services/client';
import confirm from '../../../services/confirm';
import useProperty from '../../useProperty';

const BusinessUsers = () => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));
  const hasBusinessPermissions = usePermissions(
    { businessId: business.id, propertyId: property.id },
    ['property.business.access.user.search', 'property.business.location.search'],
  );

  const [noPermissions, setNoPermissions] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refetchId, setRefetchId] = useState(0);
  const [isInviting, setIsInviting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [operationError, setOperationError] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [query, setQuery] = useQuerystringParam('query', '', { squash: true });
  const [debouncedQuery] = useDebounce(query, 750);
  const [selectedRoles, setSelectedRoles] = useQuerystringParam(
    'roles',
    '',
    {
      transport: {
        serialize: (v: []) => v && v.join(','),
        parse: (v: string) => v && v.split(','),
      },
      squash: true,
    });

  const loadUsers = () => client.properties.for(property.id).businesses.for(business.id).users.searchUsers({
    query: debouncedQuery || undefined,
    roleIds: selectedRoles || undefined,
    context: ['PB', 'PBL'],
  });

  const loadLocations = () => client
    .properties.for(property.id)
    .businesses.for(business.id)
    .locations.list();

  useEffect(() => {
    const run = async () => {
      if (hasBusinessPermissions) {
        const locs = await loadLocations();
        setLocations(locs.items);
      }
    };

    run();
  }, [property, business, hasBusinessPermissions]);

  useEffect(() => {
    const run = async () => {
      if (hasBusinessPermissions) {
        const bizRoles = await client.roles.list({ type: 'property-business' });
        const locRoles = await client.roles.list({ type: 'property-business-location' });
        setRoles([...bizRoles.items, ...locRoles.items]);
      }
    };

    run();
  }, [hasBusinessPermissions]);

  useEffect(() => {
    if (hasBusinessPermissions === false) {
      setNoPermissions(true);
    }
  }, [hasBusinessPermissions]);

  const addUserSubmit = async (values: Record<string, any>) => {
    setIsInviting(true);
    setOperationError('');
    const { email, name, roles: businessRoles = [], locationRoles = [] } = values;

    if (!businessRoles?.length && !Object.entries(locationRoles).length) {
      setIsInviting(false);
      setOperationError('No roles selected');
      return;
    }

    const runLocationRequests = async (previous: any, entry: any) => {
      const [locationId, role] = entry;
      await previous;

      return client
        .properties.for(property.id)
        .businesses.for(business.id)
        .locations.for(locationId)
        .users.invite({ email, name }, { id: role[0].id }, true);
    };

    try {
      if (businessRoles.length > 0) {
        await client
          .properties.for(property.id)
          .businesses.for(business.id)
          .users.invite(
            { email, name },
            businessRoles.map((item: string) => ({ id: item })),
            true,
          );
      }
      if (Object.entries(locationRoles).length > 0) {
        // locations only have one role per location per user, so still need to be run sequentially
        await Object.entries(locationRoles || {})?.reduce(runLocationRequests, Promise.resolve());
      }

      onClose();
      setRefetchId(refetchId + 1);
    } catch (error: any) {
      setOperationError(error);
      throw new Error(error);
    } finally {
      setIsInviting(false);
    }
  };

  const removeUser = async (user: User) => {
    const shouldDelete = confirm(`Are you sure you want to delete ${user.email}?`);
    if (!shouldDelete) return null;
    setIsDeleting(true);
    setOperationError('');

    try {
      await client
        .properties.for(property.id)
        .businesses.for(business.id)
        .users.for(user.id)
        .remove({ recursive: true });
    } catch (error: any) {
      setOperationError(error);
    } finally {
      setIsDeleting(false);
      setRefetchId(refetchId + 1);
    }

    return true;
  };

  if (noPermissions) {
    return (
      <Box maxW="7xl" mx="auto" w="100%" h="90vh" mt="0 !important" pt="40px" borderTop="1px" borderColor="gray.200">
        <Flex flexDir="column" justify="center" align="center" h="100%" gap={5} w="500px" mx="auto">
          <Icon as={ViewOffIcon} boxSize="5em" color="gray.500" />
          <Text fontSize="2xl" fontWeight={500}>Business Permission Required</Text>
          <Text fontSize="lg" textAlign="center">
            You do not have the appropriate business role to view this page.{' '}
            If this is an error, please contact your business manager.
          </Text>
        </Flex>

      </Box>
    );
  }

  return (
    <Box maxW="7xl" w="100%" px={{ base: 2, md: 0 }} mt="0 !important" pt="40px" borderTop="1px" borderColor="gray.200">
      <AddUserView
        isOpen={isOpen}
        onClose={onClose}
        type="business"
        roleSectionHeading="Business &amp; Location Roles"
        primaryRoles={roles.filter(role => role.type === 'property-business')}
        secondaryRoles={roles.filter(role => role.type === 'property-business-location')}
        roleTypes={['Business Roles', 'Location Roles']}
        locations={locations}
        onSubmit={addUserSubmit}
        isLoading={isInviting}
        error={operationError}
      />

      <Flex className="top-container" mt="16px" mb="24px" gap="1em" justify="end">
        <Flex
          direction={{ md: 'row' }}
          className="controls"
          paddingLeft={{ base: '0', lg: '40%' }}
          align="flex-end"
          w="100%"
          justify="flex-end"
          gap="1em"
        >
          <InputGroup flexGrow={1}>
            <InputLeftElement color="gray.300">
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
              placeholder="Search"
            />
          </InputGroup>
          <RoleFilter
            key={selectedRoles}
            roles={roles}
            selectedRoles={selectedRoles}
            onSubmit={setSelectedRoles}
          />
          <Button colorScheme="blue" onClick={onOpen} leftIcon={<AddIcon />} minW="100px">Add User</Button>
        </Flex>
      </Flex>

      <Box py={5}>
        <WaitFor waitFor={hasBusinessPermissions}>
          <UserListView
            loadUsers={loadUsers}
            removeUser={removeUser}
            isDeleting={isDeleting}
            query={debouncedQuery}
            refetchId={refetchId}
            selectedRoles={selectedRoles}
            currentUser={currentUser}
            operationError={operationError}
            roles={roles}
            baseUrl={`/business/${business.id}/settings/team`}
            context="business"
            locations={locations}
          />
        </WaitFor>
      </Box>
    </Box>
  );
};

export default BusinessUsers;
