import { CloseIcon } from '@chakra-ui/icons';
import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  AspectRatio,
  Box,
  IconButton,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { Field } from 'react-final-form';
import imageParams from 'web-react-ui/src/utils/imageParams';
import imageAttributionService from './imageAttributionService';
import Pips from './Pips';

const SuggestionImageCarousel = ({ value, onChange, editable }: {
  value: Array<string>, onChange?: (v: Array<string>) => void, editable?: boolean
}) => {
  const max = value?.length || 0;

  const [current, setCurrent] = useState(0);

  const nextImage = useMemo(
    () => () => setCurrent((v) => {
      return (v + 1) % max;
    }),
    [max],
  );

  const removeIndex = useMemo(
    () => (index: number) => {
      if (!onChange || !editable) return;
      const newValue = value.slice();
      newValue.splice(index, 1);
      onChange(newValue);
      setCurrent(index % (max - 1));
    },
    [value, max, onChange, editable],
  );

  return (
    <Box>
      <AspectRatio
        ratio={1}
        position="relative"
        maxW="375px"
        m="auto"
        borderRadius="2em"
        overflow="hidden"
        bg="gray.50"
      >
        <Box onClick={nextImage}>
          {(value || []).map((url, index) => {
            const attribution = imageAttributionService.getAttribution(url);
            const croppedImageUrl = imageParams(url, { w: 375, h: 375, fit: 'crop' });
            return (
              <Box key={index} hidden={index !== current} position="relative">
                <Attribution attribution={attribution} />
                <Image src={croppedImageUrl} fit="cover" m="auto" />
              </Box>
            );
          })}
          <IconButton
            icon={<CloseIcon />}
            position="absolute"
            top="1em"
            right="1em"
            borderRadius="full"
            onClick={() => removeIndex(current)}
            aria-label="Remove Image"
            display={editable ? 'initial' : 'none'}
          />
          <Pips current={current} max={max} position="absolute" bottom="1em" />
        </Box>
      </AspectRatio>
    </Box>
  );
};

const Attribution = ({ attribution, ...rest }: { attribution: { name: string, url: string } }) => {
  // Prevent switching images when opening attribution links
  const stopPropagation = useCallback(e => e.stopPropagation(), []);
  if (!attribution) return null;
  return (
    <Box
      position="absolute"
      w="100%"
      h="100%"
      opacity={0}
      _hover={{ opacity: 1 }}
      sx={{ transition: 'opacity 250ms' }}
      p="1em"
    >
      <Box
        onClick={stopPropagation}
        {...rest}
      >
        <Text
          fontSize="lg"
          color="white"
          fontWeight="500"
          textShadow="1px 0px 1px black, 0px 0px 2px black, 0px 0px 3px black, 0px 0px 5px black, 0px 0px 10px black"
        >
          Photo by{' '}
          <Link
            href={`${attribution.url}?utm_source=GetintheLoop-Prod&utm_medium=referral `}
            textDecoration="underline"
            target="_blank"
            rel="noreferrer">
            {attribution.name}
          </Link>
          {' '}on{' '}
          <Link
            href="https://unsplash.com"
            textDecoration="underline"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

const SuggestionImageCarouselField = ({ name, editable }: { name: string, editable?: boolean }) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <SuggestionImageCarousel {...input} editable={editable} />
      )}
    </Field>
  );
};

export {
  SuggestionImageCarousel,
  SuggestionImageCarouselField,
};
