import { Alert, Stack, AlertDescription, AlertIcon, AlertTitle, Flex, Link } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import usePagedList from 'shared/src/hooks/usePagedList';
import ProfileHealth from 'shared/src/components/ProfileHealth';
import PostStats from 'shared/src/components/PostStats';
import PostsFilters from 'shared/src/components/PostsFilters';
import { transport, useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import useProperty from '../../useProperty';
import SectionHeader from './components/SectionHeader';
import Post from './interfaces/Post.interface';
import PostList from './PostList';
import SocialPostListEmpty from './SocialPostListEmpty';

const fetchPosts = async ({
  business,
  currFilters
}: {
  business: { id: string };
  currFilters: { contentType: string; stateGroup: string };
}): Promise<{ items: Array<Post> } | undefined> => {
  if (!business) return undefined;
  const showNonInternalContentOnly = {
    ...currFilters,
    stateGroup: currFilters.stateGroup === 'all' ? '!generating' : currFilters.stateGroup
  };
  const removeKeysIfEqualsAll = Object.fromEntries(
    Object.entries(showNonInternalContentOnly).filter(([_, value]) => value !== 'all')
  );
  return client.businesses.for(business.id).social.posts.list(removeKeysIfEqualsAll);
};

const PostListView = () => {
  const property = useProperty();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const profile = useSelector(state => state.social.profile);
  const [currFilters, setCurrFilters] = useState({ contentType: 'all', stateGroup: 'all' });
  const [status, setStatus] = useQuerystringParam('status', '', { squash: true });
  const [range, setRange] = useQuerystringParam('range', '', { transport: transport.positiveNumber, squash: true });
  const postList = usePagedList(fetchPosts, { property, business, currFilters, range });
  const controls = <></>;
  const hasLinkedSocials = profile.socialAccounts.some(a => a.linked);
  const hasNoPosts =
    postList.empty &&
    postList.page.current === 1 &&
    currFilters.contentType === 'all' &&
    currFilters.stateGroup === 'all';
  return (
    <PageLayout>
      <ViewHeader title="Social AI">
        <ActionMenu
          actions={[
            {
              label: 'Settings',
              extraProps: {
                as: RouterLink,
                to: './social/settings'
              }
            },
            {
              label: 'New Custom Post...',
              extraProps: {
                as: RouterLink,
                to: './social/new'
              },
              hidden: true
            }
          ]}
        />
      </ViewHeader>
      <Flex direction="column" gap="1em">
        {!hasNoPosts ? (
          <>
            <PostStats businessId={business.id} />
            <Stack direction="row" w="100%" justify="space-between" align="center" mt="4" mx="0">
              <SectionHeader level={3} controls={controls}>
                Posts
              </SectionHeader>
              <PostsFilters currFilters={currFilters} callback={setCurrFilters} />
            </Stack>
            <ProfileHealth profile={profile} context="dashboard" hasLinks />
            {!hasLinkedSocials ? (
              <Alert status="warning" fontSize="lg">
                <AlertIcon />
                <Flex direction="column" gap="4px">
                  <AlertTitle>No Linked Social Media Accounts</AlertTitle>
                  <AlertDescription>
                    Social AI cannot find any linked social media accounts. At least one linked account is required for
                    Social AI to generate and manage posts and stories.
                  </AlertDescription>
                  <Link color="blue.500" as={RouterLink} to="./social/settings">
                    Link Account Now
                  </Link>
                </Flex>
              </Alert>
            ) : null}
            <PostList pagedList={postList} business={business} />
            <AutoPagination pagedList={postList} />
          </>
        ) : (
          <SocialPostListEmpty />
        )}
      </Flex>
    </PageLayout>
  );
};

export default PostListView;
